import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebounceInput } from 'react-debounce-input';
import { toast } from 'react-toastify';
import { faWeebly } from '@fortawesome/free-brands-svg-icons';
import { isEmpty } from 'services/global';

const _ = require('lodash');

const PlayersTable = ({ players, watchlist, currentTab, filters, orderBy, watchlistMax, handleWatchlistActions }) => {
    const [watchlistArr, setWatchlistArr] = useState(watchlist);
    const [playersCloneArr, setPlayersCloneArr] = useState(_.cloneDeep(players));

    const mapWatchlist = async (watchlist) => {
        let tmpWatchlistArr = [];
        await watchlist.map(p => {
            return tmpWatchlistArr.push(p.fkSkater);
        });
        setWatchlistArr(tmpWatchlistArr);
    }

    useEffect(() => {
        setPlayersCloneArr(_.cloneDeep(players));
    }, [players]);

    useEffect(() => {
        mapWatchlist(watchlist);
    }, [watchlist]);

    const addToWatchlist = (playerID, name) => {
        watchlist.push({ fkSkater: playerID, rank: (watchlistMax + 1) });
        mapWatchlist(watchlist);
        handleWatchlistActions('add', playerID, name);
    }

    const removeFromWatchlist = (playerID) => {
        handleWatchlistActions('remove', playerID);
    }

    const updateRankings = (playerID, e) => {
        const newRank = parseInt(e.target.value);

        if (newRank > watchlistMax || newRank < 1) {
            return toast.error("Rank must be between 1 and " + watchlistMax + "!");
        }
        handleWatchlistActions('update', playerID, newRank);
    }

    const renderIcons = (injury) => {
        if (!isEmpty(injury)) {
            return (
                <FontAwesomeIcon className="fa-lg handCursor dontdoit" icon="info-circle" title={injury} style={{ color: 'red', marginLeft: 3 }} />
            );
        }
        else {
            return null;
        }
    }

    const renderPlayers = () => {
        let draftYear = '';
        let position = [''];
        let team = '';
        let search = '';

        if (filters !== undefined && filters.draftYear !== undefined && filters.draftYear.value !== 'All') {
            draftYear = filters.draftYear.value;
        }

        if (filters !== undefined && filters.position !== undefined && filters.position.value === 'Skaters') {
            position = ["FWD", "DEF"];
        }
        else if (filters !== undefined && filters.position !== undefined && filters.position.value !== 'All') {
            position = [filters.position.value];
        }

        if (filters !== undefined && filters.team !== undefined && filters.team.value !== 'All') {
            team = filters.team.value;
        }
        if (filters !== undefined && filters.search !== undefined) {
            search = filters.search;
        }
        var sortFunc = (a, b) => (orderBy.order === 'ASC' ? (a[orderBy.key] - b[orderBy.key]) : (b[orderBy.key] - a[orderBy.key]));

        if (orderBy.key === 'draftYear' || orderBy.key === 'name' || orderBy.key === 'position') {
            sortFunc = (a, b) => (orderBy.order === 'ASC' ? (b[orderBy.key].localeCompare(a[orderBy.key]))
                : a[orderBy.key].localeCompare(b[orderBy.key]));
        }
        
        return playersCloneArr
            .filter(player => player.draftYear.includes(draftYear))
            .filter(player => position.some(pos => player.position.includes(pos)))
            .filter(player => player.team.includes(team))
            .filter(player => player.name.toLowerCase().includes(search.toLowerCase()))
            .filter(currentTab === 'watchlist' ? (player => watchlistArr.some(p => player._id === p)) : (() => true))
            .sort(sortFunc)
            .map(player => {
                watchlist.forEach(p => {
                    if (p.fkSkater === player._id) {
                        player.rank = p.rank;
                    }
                });

                return (
                    <tr key={player._id}>
                        { currentTab === 'watchlist' &&
                            <td>
                                <DebounceInput id={`rank${player.rank}`} name="rank" className="rankInput form-control" type="number"
                                    debounceTimeout={1000}
                                    min={1}
                                    max={watchlistMax}
                                    onChange={e => updateRankings(player._id, e)}
                                    value={player.rank}
                                />
                            </td>
                        }
                        <td>
                            {currentTab === 'players' && (!watchlistArr.includes(player._id)) &&
                                <FontAwesomeIcon className="handCursor mr5 fa-lg" icon={faWeebly} style={{ color: 'green' }}
                                    onClick={() => addToWatchlist(player._id, player.name)}
                                    title={`Add ${player.name} to my watchlist`} />
                            }
                            {player.name} ({player.team})
                            {renderIcons(player.injury)}
                        </td>
                        <td>{player.position}</td>
                        <td>{player.draftYear}</td>
                        <td>{player.gamesPlayed}</td>
                        <td>{player.points}</td>
                        <td>{player.pointsPerGame.toFixed(2)}</td>
                        <td>{player.wins === null ? 'N/A' : player.wins}</td>
                        <td>{player.shutouts === null ? 'N/A' : player.shutouts}</td>
                        { currentTab === 'watchlist' &&
                            <td className="handCursor" title={`Remove ${player.name} from your watchlist`}
                                onClick={() => removeFromWatchlist(player._id)}>
                                <FontAwesomeIcon icon="trash" style={{ color: 'red' }} />
                            </td>
                        }
                    </tr>
                )
            }
        )
    }
    return (
        <tbody>
            {renderPlayers()}
        </tbody>
    );
}

export default PlayersTable;