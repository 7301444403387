import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDraftYears } from 'api/api_draft_years';
import { getTeams } from 'api/api_teams';
import { connect } from 'react-redux';
import { isEmpty } from 'services/global';
import Select from 'react-select';

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            draftYear: { value: 'All', label: 'All' },
            position: { value: 'All', label: 'All' },
            team: { value: 'All', label: 'All' },
            search: '',
            positionOptions: [
                { value: 'All', label: 'All' },
                { value: 'Skaters', label: 'Skaters' },
                { value: 'FWD', label: 'Forwards' },
                { value: 'DEF', label: 'Defensemen' },
                { value: 'GOA', label: 'Goalies' },
            ]
        }
    }

    componentDidMount() {
        this.props.dispatch(getDraftYears());
        this.props.dispatch(getTeams());
    }

    componentWillUnmount() {
        this.props.dispatch({ type: 'UNMOUNT_DRAFT_YEARS' });
        this.props.dispatch({ type: 'UNMOUNT_TEAMS' });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async getStats(filter, selectedOption) {
        var value = '';
        if (!isEmpty(selectedOption.currentTarget) && !isEmpty(selectedOption.currentTarget.value)) {
            value = selectedOption.currentTarget.value;
        }
        else if(!isEmpty(selectedOption.value)){
            value = selectedOption.value;
        }
        //want to clear search 
        if (filter.includes('eraser')) {
            await this.setStateAsync({ search: '' });
        }
        //want to search for something
        if (filter === 'search') {
            await this.setStateAsync({ search: value });
        }
        //filtering by draft year, position, or team
        else {
            if (filter === 'position' && value === 'GOA' && this.state.draftYear.value !== 'Other') {
                await this.setStateAsync({ draftYear: {value: 'All', label: 'Other'}, position: {value: 'GOA', label: 'Goalies'} });
            }
            else if (filter === 'position' && value !== 'GOA' && this.state.draftYear.label === 'Other') {
                await this.setStateAsync({ draftYear: {value: 'All', label: 'All'}, position: selectedOption });
            }
            else {
                await this.setStateAsync({ [filter]: selectedOption });
            }
        }
        //call the api to get the players matching the filters
        await this.props.handleSearch(this.state);
    }

    renderDraftYears(draftYears) {
        if (!isEmpty(draftYears) && draftYears.length > 0) {
            let draftYearsArr = [];

            draftYears.map(dy => {
                //Want 'All' in the first position, so splice it in
                if (dy.year.toLowerCase() === 'all') {
                    return draftYearsArr.splice(0, 0, { value: 'All', label: 'All' });
                }
                else {
                    return draftYearsArr.push({ value: dy.year, label: dy.year });
                }
            });
            return draftYearsArr;
        }
    }

    renderTeams(teams) {
        let teamsArr = [];
        teamsArr.splice(0, 0, { value: "All", label: "All" });

        if (!isEmpty(teams) && teams.length > 0) {
            teams.map(t => {
                return teamsArr.push({ value: t.pkAbbreviation, label: t.fullTeamName });
            });
            return teamsArr;
        }
        else {
            return teamsArr;
        }
    }

    render() {
        const { draftYears, teams } = this.props;

        return (
            <div className="row mb10">
                <div className="col-12 col-md-6 col-lg-3 mb5">
                    <label>Draft Year</label>
                    <span style={{ color: 'black' }}>
                        <Select id="draftYear"
                            name="draftYear"
                            value={this.state.draftYear}
                            options={this.renderDraftYears(draftYears)}
                            onChange={this.getStats.bind(this, 'draftYear')}
                            isDisabled={this.state.position.value === 'GOA'}
                            title={`${this.state.position.value === 'GOA' ? 'Disabled as all goalies draft years are set to Other, so display "All" goalies' : ''}`}
                        />
                    </span>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb5">
                    <label>Position</label>
                    <span style={{ color: 'black' }}>
                        <Select id="position"
                            name="position"
                            value={this.state.position}
                            defaultValue={this.state.position}
                            options={this.state.positionOptions}
                            onChange={this.getStats.bind(this, 'position')}
                        />
                    </span>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb5">
                    <label>Team</label>
                    <span style={{ color: 'black' }}>
                        <Select id="team"
                            name="team"
                            value={this.state.team}
                            defaultValue={this.state.team}
                            options={this.renderTeams(teams)}
                            onChange={this.getStats.bind(this, 'team')}
                        />
                    </span>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb5">
                    <label>Search Player</label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <div id="eraserDiv" className="input-group-text handCursor" style={{ backgroundColor: 'white' }}
                                onClick={this.getStats.bind(this, 'eraser')}>
                                <FontAwesomeIcon id="eraserIcon" icon="eraser" />
                            </div>
                        </div>
                        <input id="search" name="search" className="form-control" type="text"
                            placeholder="Search"
                            onChange={this.getStats.bind(this, 'search')}
                            value={this.state.search}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        draftYears: state.draftYears,
        teams: state.players.teams,
    }
}

export default connect(mapStateToProps)(Search);